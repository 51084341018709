import "./styles.scss";
import Slider from "./Slider";

function Carousel() {
  const images = [
    "carousel/carousel-2.png",
    "https://www.youtube.com/embed/rnKsvEXQxTM? =1",
    "carousel/carousel-2.png",
    "https://www.youtube.com/embed/rnKsvEXQxTM? =1",
  ];

  function handleSubscribeClick() {}

  return (
    <>
      <div className="carousel-section carousel-top">
        <Slider items={images} type={"iframe"} />
      </div>
      <div className="carousel-section carousel-mid">
        <div className="dont-miss">
          <div className="black">Don't Miss</div>
          <div className="black last">new about</div>
          <div>georgian football</div>
        </div>
        <div className="mid-info">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Accusantium aspernatur at conseq cumque cupiditate dolore dolores
            dolorum ducimus earum esse eum eveniet expedita, facere fuga impedit
            ipsa laboriosam magnam omnis placeat possimus provident quas qui
            repudiandae ut veniam? Autem commodi cum cupiditate illum iure
            laboriosam possimus, voluptates.
          </p>
          <button onClick={handleSubscribeClick}>
            subscribe
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path d="m700-300-57-56 84-84H120v-80h607l-83-84 57-56 179 180-180 180Z" />
            </svg>
          </button>
        </div>
      </div>
      <div className="carousel-section carousel-bot">
        <div className="gome gome1">the home of</div>
        <div className="gome gome2">georgian football</div>
      </div>
    </>
  );
}

export default Carousel;
