// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.impact-stats {
  width: 70%;
  margin-inline: auto;
}
.impact-stats ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin-inline: 10%;
}
.impact-stats ul li {
  margin: 1rem 7%;
  width: 19%;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 1.7rem;
  color: var(--accent-500);
}
.impact-stats ul li p {
  color: black;
  font-weight: normal;
  font-size: 1rem;
}

@media screen and (max-width: 600px) {
  .impact-stats {
    width: 100%;
  }
  .impact-stats ul {
    margin: 0;
    padding: 0;
  }
  .impact-stats ul li {
    margin: 1rem;
    width: 35%;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 1.7rem;
    color: var(--accent-500);
  }
  .impact-stats ul li p {
    color: black;
    font-weight: normal;
    font-size: 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Impact/styles.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,mBAAA;AACF;AAAE;EACE,aAAA;EACA,eAAA;EACA,uBAAA;EACA,gBAAA;EAEA,kBAAA;AACJ;AACI;EACE,eAAA;EACA,UAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,iBAAA;EACA,wBAAA;AACN;AACM;EACE,YAAA;EACA,mBAAA;EACA,eAAA;AACR;;AAIA;EACE;IACE,WAAA;EADF;EAEE;IACE,SAAA;IACA,UAAA;EAAJ;EAGI;IACE,YAAA;IACA,UAAA;IACA,mBAAA;IACA,kBAAA;IACA,iBAAA;IACA,iBAAA;IACA,wBAAA;EADN;EAGM;IACE,YAAA;IACA,mBAAA;IACA,eAAA;EADR;AACF","sourcesContent":[".impact-stats {\n  width: 70%;\n  margin-inline: auto;\n  ul {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    list-style: none;\n\n    margin-inline: 10%;\n\n    li {\n      margin: 1rem 7%;\n      width: 19%;\n      align-items: center;\n      text-align: center;\n      font-weight: bold;\n      font-size: 1.7rem;\n      color: var(--accent-500);\n\n      p {\n        color: black;\n        font-weight: normal;\n        font-size: 1rem;\n      }\n    }\n  }\n}\n@media screen and (max-width: 600px) {\n  .impact-stats {\n    width: 100%;\n    ul {\n      margin: 0;\n      padding: 0;\n      //justify-content: flex-start;\n\n      li {\n        margin: 1rem;\n        width: 35%;\n        align-items: center;\n        text-align: center;\n        font-weight: bold;\n        font-size: 1.7rem;\n        color: var(--accent-500);\n\n        p {\n          color: black;\n          font-weight: normal;\n          font-size: 1rem;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
