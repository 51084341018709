import './styles.scss'

function FAQ() {
    return (<>
        <div className="faq-wrapper">
            <div className="margin-div">
                <h1>
                    FAQ
                </h1>
                <h1>
                    WHICH COUNTRIES DO YOU SHIP TO
                </h1>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi cupiditate distinctio doloribus eius
                    fuga illum inventore modi molestias nulla optio, placeat repudiandae, sapiente ut. Assumenda dolore,
                    ipsa ipsam nam.
                </p>
                <p>
                    Asperiores at autem beatae consequuntur culpa cum dicta.
                </p>
                <h1>
                    WHEN WILL I RECEIVE MY ORDER?
                </h1>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi cupiditate distinctio doloribus eius
                    fuga illum inventore modi molestias nulla optio, placeat repudiandae, sapiente ut. Assumenda dolore,
                    ipsa ipsam nam.
                </p>
            </div>
        </div>
    </>);
}

export default FAQ;