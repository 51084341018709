// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {



    --font-body: Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --font-mono: 'Fira Mono', monospace;

    --accent-500: #d42427;

    --text-100: #FFFFFF;
    --text-300: #666666;

    --bg-300: #191919;


    --border-radius-small: 5px;
    --border-radius-normal: 10px;
    --border-radius-round: 10rem;

    --column-width: 42rem;
    --column-margin-top: 4rem;
    font-family: 'Inter', sans-serif;
    color: var(--text-100);
}

/* hide scroll bar*/
html::-webkit-scrollbar {
    display: none;
}

html {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.not-white {
    min-height: 100svh;
    background-color: var(--bg-300);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;;;;IAII;wDACoD;IACpD,mCAAmC;;IAEnC,qBAAqB;;IAErB,mBAAmB;IACnB,mBAAmB;;IAEnB,iBAAiB;;;IAGjB,0BAA0B;IAC1B,4BAA4B;IAC5B,4BAA4B;;IAE5B,qBAAqB;IACrB,yBAAyB;IACzB,gCAAgC;IAChC,sBAAsB;AAC1B;;AAEA,mBAAmB;AACnB;IACI,aAAa;AACjB;;AAEA;IACI,wBAAwB,EAAE,gBAAgB;IAC1C,qBAAqB,EAAE,YAAY;AACvC;;AAEA;IACI,SAAS;IACT,gCAAgC;IAChC,mCAAmC;IACnC,kCAAkC;AACtC;;AAEA;IACI,kBAAkB;IAClB,+BAA+B;AACnC;;AAEA;IACI;aACS;AACb;;AAEA;IACI,SAAS;IACT,UAAU;AACd","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');\n\n:root {\n\n\n\n    --font-body: Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,\n    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;\n    --font-mono: 'Fira Mono', monospace;\n\n    --accent-500: #d42427;\n\n    --text-100: #FFFFFF;\n    --text-300: #666666;\n\n    --bg-300: #191919;\n\n\n    --border-radius-small: 5px;\n    --border-radius-normal: 10px;\n    --border-radius-round: 10rem;\n\n    --column-width: 42rem;\n    --column-margin-top: 4rem;\n    font-family: 'Inter', sans-serif;\n    color: var(--text-100);\n}\n\n/* hide scroll bar*/\nhtml::-webkit-scrollbar {\n    display: none;\n}\n\nhtml {\n    -ms-overflow-style: none; /* IE and Edge */\n    scrollbar-width: none; /* Firefox */\n}\n\nbody {\n    margin: 0;\n    font-family: 'Inter', sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n}\n\n.not-white {\n    min-height: 100svh;\n    background-color: var(--bg-300);\n}\n\ncode {\n    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\nh1, h2, h3, h4, h5, h6, p {\n    margin: 0;\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
