import "./styles.scss";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import {useLocation} from "react-router-dom";
import {useEffect} from "react";


let Layout = ({children}) => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const fatherlessPaths = ['/FAQ', '/ToS', '/Cookies'];
    const isEmpty = fatherlessPaths.includes(location.pathname);

    return (
        <div className={"layout " + (isEmpty && "not-white")}>
            <Header/>
            <main>
                {children}
            </main>
            {!isEmpty && <Footer />}
        </div>
    );
}

export default Layout;
