import './styles.scss';

function Impact(){
    return(
        <>
            <div className="impact-stats">
                <ul>
                    <li>55M+
                        <p>Lorem ipsum dolor sit amet. </p>
                    </li>
                    <li>55M+
                        <p>Accusamus doloremque minus non placeat. </p>
                    </li>
                    <li>55M+
                        <p>Dignissimos est eveniet exercitationem illo. </p>
                    </li>
                    <li>55M+
                        <p>Inventore maiores mollitia sapiente tempore? </p>
                    </li>
                    <li>55M+
                        <p>Ad fugit pariatur tempore voluptates? </p>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default Impact;