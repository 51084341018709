import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from "../../Components/Logo";
import "./styles.scss";

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header>
            <div className="nav-logo">
                <Logo size={46}/>
            </div>
            <div className="burger-menu" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className={isMenuOpen ? 'overlay' : ''}></div>
            <nav className={`nav-wrapper ${isMenuOpen ? 'open' : ''}`}>
                <NavLink to="/" onClick={toggleMenu}><p>home</p></NavLink>
                <NavLink to="/partnership" onClick={toggleMenu}><p>partnership</p></NavLink>
                <NavLink to="/about" onClick={toggleMenu}><p>about</p></NavLink>
            </nav>
        </header>
    );
}

export default Header;
