import React, {useEffect, useState, useRef} from "react";
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Slider({items}) {
    const [slideCount, setSlideCount] = useState(3);
    const sliderRef = useRef();

    useEffect(() => {
        const handleResize = () => {
            const currentWidth = window.innerWidth;
            if (currentWidth < 900) {
                setSlideCount(1);
            } else if (currentWidth < 1200) {
                setSlideCount(2);
            } else {
                setSlideCount(3);
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const settings = {
        infinite: true,
        speed: 300,
        slidesToShow: slideCount,
        slidesToScroll: 1,
        gap: 1,
    };

    const goToNext = () => {
        sliderRef.current.slickNext();
    };

    const goToPrev = () => {
        sliderRef.current.slickPrev();
    };

    const isIframe = (item) => {
        return item.includes("http://") || item.includes("https://");
    };

    return (
        <>
            <div className="slider-button-wrapper">
                <button className="slider-button slider-button-prev" onClick={goToPrev}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 -960 960 960" width="32">
                        <path
                            d="m480-320 56-56-64-64h168v-80H472l64-64-56-56-160 160 160 160Zm0 240q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
                    </svg>
                </button>
                <button className="slider-button slider-button-next" onClick={goToNext}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 -960 960 960" width="32">
                        <path
                            d="m480-320 160-160-160-160-56 56 64 64H320v80h168l-64 64 56 56Zm0 240q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
                    </svg>
                </button>
            </div>
            <SlickSlider ref={sliderRef} {...settings}>
                {items.map((item, index) => (
                    <div key={index} className="slider-image-div">
                        {isIframe(item) ? (
                            <iframe
                                className="iframe"
                                src={item}
                                title={`Item ${index}`}
                                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                loading="lazy"
                            ></iframe>
                        ) : (
                            <img
                                src={item}
                                alt={`Item ${index}`}
                                loading="lazy"
                            />
                        )}
                    </div>
                ))}
            </SlickSlider>
        </>
    );
}

export default Slider;
