// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq-wrapper {
  background-color: var(--bg-300);
  display: flex;
}
.faq-wrapper .margin-div {
  margin: 10rem auto 4rem;
  width: 75ch;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.faq-wrapper .margin-div h1, .faq-wrapper .margin-div h3 {
  margin-block: 1rem;
  text-transform: uppercase;
}
.faq-wrapper .margin-div p {
  font-size: 1.2rem;
  line-height: 1.5;
}`, "",{"version":3,"sources":["webpack://./src/Layouts/FAQ/styles.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,aAAA;AACF;AACE;EACE,uBAAA;EACA,WAAA;EAEA,aAAA;EAEA,sBAAA;EACA,SAAA;AADJ;AAGI;EACE,kBAAA;EACA,yBAAA;AADN;AAII;EACE,iBAAA;EACA,gBAAA;AAFN","sourcesContent":[".faq-wrapper {\n  background-color: var(--bg-300);\n  display: flex;\n\n  .margin-div{\n    margin: 10rem auto 4rem;\n    width: 75ch;\n\n    display: flex;\n\n    flex-direction: column;\n    gap: 2rem;\n\n    h1, h3 {\n      margin-block: 1rem;\n      text-transform: uppercase;\n    }\n\n    p {\n      font-size: 1.2rem;\n      line-height: 1.5;\n    }\n  }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
