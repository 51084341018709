import './styles.scss'

function ToS() {
    return (<>
        <div className="faq-wrapper">
            <div className="margin-div">
                <h1>
                    TERMS OF SERVICE
                </h1>
                <h1>
                    OVERVIEW
                </h1>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi cupiditate distinctio doloribus eius
                    fuga illum inventore modi molestias nulla optio, placeat repudiandae, sapiente ut. Assumenda dolore,
                    ipsa ipsam nam officiis quas repellendus! Consectetur cum ducimus, earum eum exercitationem expedita
                    laborum maxime mollitia natus nobis quasi qui quo totam! Aperiam aut harum voluptatum? Accusantium animi
                    assumenda consectetur distinctio impedit magnam rem repudiandae tempora vel voluptatem? Aliquam at
                    consequatur dicta dignissimos distinctio, impedit molestias, natus neque nostrum quam quasi qui
                    quibusdam, quis quisquam reiciendis sint sit velit veritatis! Accusamus aliquam amet beatae consequatur
                    deleniti deserunt distinctio dolor exercitationem facilis fuga incidunt ipsa libero maiores molestias
                    non nostrum nulla officiis pariatur, placeat possimus quas sit sunt tempora? Accusantium adipisci amet
                    asperiores at beatae corporis cupiditate deserunt dignissimos eaque eius ex exercitationem facilis, fuga
                    id impedit libero non, odio omnis optio porro provident, quam quas quasi qui repellendus rerum ut
                    voluptates. Consequatur corporis deleniti ea fuga impedit mollitia odio quibusdam! Aut autem impedit
                    modi sequi? Asperiores error est inventore ipsum qui! A accusamus aspernatur culpa dolorem error eum ex
                    expedita hic, impedit iste maiores minima molestiae obcaecati praesentium quasi quos ratione sed soluta?
                    Animi asperiores consequatur debitis in modi nam nobis qui reiciendis velit?
                </p>
                <p>
                    Amet at consectetur consequatur, cumque delectus eos in iure labore magnam maiores minima mollitia,
                    nostrum officiis pariatur placeat, quae qui vero voluptas. Aliquid architecto assumenda commodi, est ex
                    illo ipsum laboriosam magni maxime mollitia neque sed sequi, suscipit. Ab autem, dolore laudantium
                    nostrum officiis omnis perferendis soluta. Assumenda deserunt, dicta enim error esse eum harum in iure
                    laboriosam laudantium magnam molestiae necessitatibus neque nesciunt quisquam ratione repellat
                    repudiandae sunt totam veniam! Ab accusantium asperiores cupiditate debitis dicta explicabo facere
                    fugiat incidunt iste magnam nisi nostrum, numquam officia tempora temporibus vero vitae. Deserunt maxime
                    repudiandae similique voluptate. Accusantium animi asperiores blanditiis cum, delectus dolores enim est
                    ex fugiat fugit, incidunt inventore ipsum laboriosam magni modi nesciunt nisi nobis odio odit officiis
                    optio porro quaerat quasi quod recusandae repellat reprehenderit similique suscipit, totam voluptatum?
                    Consectetur, delectus doloribus explicabo fuga ipsum ratione rem! Animi at cum delectus dolor eveniet
                    incidunt inventore ipsum maxime molestiae molestias nihil numquam obcaecati odit, quam quisquam,
                    reprehenderit sint voluptatem! Accusamus aliquid corporis dolorem eveniet id in incidunt ipsam, minima
                    modi molestias perferendis quod ratione repellat rerum sint sunt totam unde voluptatibus! Delectus,
                    nesciunt odio. Aliquam animi aspernatur dolorum fugit quae quidem rem suscipit! Facilis nemo non tenetur
                    voluptatum.
                </p>
                <p>
                    Adipisci aut consequatur, cum eius eos est ipsam, iste mollitia nobis perferendis quam quos repellat,
                    tempore ullam vero. Ab, accusamus amet delectus deleniti dicta ducimus earum, inventore ipsam ipsum iure
                    labore laudantium nam, nihil officiis porro quis quod quos repellendus saepe sed suscipit tempore ut.
                    Ab, accusantium aliquid asperiores assumenda cum dicta distinctio esse illo impedit in inventore ipsa,
                    iste natus neque nobis nulla odio placeat quia quibusdam repudiandae sint temporibus vitae! A, aliquam
                    consectetur doloribus eaque quae vel vero voluptates voluptatum. Ab accusantium ad adipisci animi
                    assumenda atque culpa distinctio dolorem doloremque, doloribus eligendi enim error esse eum eveniet
                    expedita fugit ipsa ipsum iusto laboriosam minus, neque nostrum optio quo recusandae reiciendis rem
                    repellat repellendus reprehenderit sint sunt suscipit tempora tenetur velit veniam vero voluptatem.
                    Adipisci asperiores commodi deleniti eos facere hic impedit incidunt ipsa modi, omnis provident
                    reprehenderit suscipit, unde vero, vitae. Animi consequatur cupiditate distinctio dolor, error et harum
                    hic ipsa ipsam iste magnam optio recusandae suscipit tenetur vitae voluptate voluptatum. Architecto aut
                    facilis maiores numquam quasi quisquam quo repellat voluptate. Ab asperiores, consectetur culpa
                    cupiditate dicta earum esse est et hic in laudantium minus mollitia natus nesciunt nihil quidem quis
                    sapiente sed, temporibus voluptatem. Et, inventore!
                </p>
                <p>
                    Commodi consequuntur, cum cumque fuga hic labore repellat tempora. Earum esse incidunt magni molestiae
                    quos. Ab alias aliquid cumque explicabo molestias necessitatibus obcaecati sint ullam voluptates
                    voluptatibus? Animi at commodi consequuntur dignissimos dolorum ducimus esse est facilis illo, itaque
                    iusto labore natus necessitatibus quasi quo quod sit voluptas! Ad, aliquid cumque cupiditate dolorem
                    dolorum ea eius eligendi eos ex exercitationem fugit iure magni omnis, provident unde? Aliquid at dolor
                    enim impedit incidunt natus nostrum, quibusdam reiciendis. Animi, distinctio eaque impedit ipsum maxime
                    quisquam veniam. Accusantium ad consequatur deserunt dolor dolores ipsum maiores pariatur unde vero
                    voluptates. Aperiam blanditiis deleniti ducimus eaque, expedita fugit iste recusandae reiciendis
                    repellat! Accusantium doloribus in laboriosam magni minima praesentium recusandae tenetur? Accusamus
                    cumque et nam praesentium saepe sed tenetur voluptates? Atque autem deserunt earum enim eos error
                    facilis fuga libero natus numquam officia officiis provident, quaerat qui quis quisquam rem
                    reprehenderit repudiandae sit sunt ullam vero voluptates! Aliquid architecto eveniet fugit magni
                    praesentium reprehenderit, voluptate. Accusantium aliquid animi aperiam aut, cum cupiditate earum, esse
                    eveniet facere iure labore libero maiores nesciunt non quas sapiente soluta sunt tempore voluptate
                    voluptatum. Accusantium adipisci blanditiis corporis dolor ex fuga, ipsam, itaque, iure optio sed veniam
                    voluptas voluptate voluptatibus!
                </p>
                <p>
                    Asperiores at autem beatae consequuntur culpa cum dicta dolores dolorum ea eligendi enim esse est eum ex
                    facere illo in incidunt inventore iste labore laborum nulla.
                </p>
                <h3>section 1</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam, cupiditate debitis doloremque
                    exercitationem illo iure molestias nisi non numquam obcaecati officiis, quasi quo quod suscipit
                    voluptate. A
                    accusantium amet aspernatur aut blanditiis consectetur cum distinctio dolore doloremque dolorum eaque
                    est ex
                    exercitationem explicabo fugit, illo ipsa labore minus molestiae mollitia nam necessitatibus nemo nobis
                    nulla officia porro quia quibusdam quo quos repellat repudiandae sapiente similique sit tempore tenetur
                    totam velit. Beatae dolore eos error ex inventore, ipsam labore nobis placeat possimus quaerat quia quis
                    repudiandae saepe sapiente, sed. Consequuntur eaque eum facere impedit iusto obcaecati pariatur
                    perspiciatis
                    placeat reiciendis, totam! </p>
                <p>Esse eveniet exercitationem labore rerum voluptate. Amet distinctio, et
                    fugiat, in inventore modi nesciunt nisi possimus quisquam ratione similique totam. Aliquam cum
                    dignissimos
                    doloribus est et praesentium quisquam reiciendis! Asperiores ex fugiat nemo odit. Architecto aspernatur
                    cumque esse est fugit illum incidunt iure modi molestias mollitia neque numquam officiis quisquam
                    repellat
                    sed, temporibus tenetur veniam. A aliquam animi autem consequatur delectus distinctio dolorum eos error
                    explicabo iste laboriosam, magni minus nam nesciunt nisi optio, pariatur, perferendis placeat quasi qui
                    rem
                    repellendus reprehenderit soluta. Et, itaque, quasi. Accusantium ad at dolores, enim et ipsam magnam
                    quasi
                    tempore? Minus porro qui sequi? </p>
                <h3>section 2</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam, cupiditate debitis doloremque
                    exercitationem illo iure molestias nisi non numquam obcaecati officiis, quasi quo quod suscipit
                    voluptate. A
                    accusantium amet aspernatur aut blanditiis consectetur cum distinctio dolore doloremque dolorum eaque
                    est ex
                    exercitationem explicabo fugit, illo ipsa labore minus molestiae mollitia nam necessitatibus nemo nobis
                    nulla officia porro quia quibusdam quo quos repellat repudiandae sapiente similique sit tempore tenetur
                    totam velit. Beatae dolore eos error ex inventore, ipsam labore nobis placeat possimus quaerat quia quis
                    repudiandae saepe sapiente, sed. Consequuntur eaque eum facere impedit iusto obcaecati pariatur
                    perspiciatis
                    placeat reiciendis, totam! </p>
                <p>Esse eveniet exercitationem labore rerum voluptate. Amet distinctio, et
                    fugiat, in inventore modi nesciunt nisi possimus quisquam ratione similique totam. Aliquam cum
                    dignissimos
                    doloribus est et praesentium quisquam reiciendis! Asperiores ex fugiat nemo odit. Architecto aspernatur
                    cumque esse est fugit illum incidunt iure modi molestias mollitia neque numquam officiis quisquam
                    repellat
                    sed, temporibus tenetur veniam. A aliquam animi autem consequatur delectus distinctio dolorum eos error
                    explicabo iste laboriosam, magni minus nam nesciunt nisi optio, pariatur, perferendis placeat quasi qui
                    rem
                    repellendus reprehenderit soluta. Et, itaque, quasi. Accusantium ad at dolores, enim et ipsam magnam
                    quasi
                    tempore? Minus porro qui sequi? </p>
            </div>
        </div>
    </>);
}

export default ToS;