import React from 'react';

const Logo = ({ size }) => {
    const style = {
        height: size,
        width: size,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    return (
        <div style={style}>
            <img src="miniLogo.webp" alt="Logo" style={{ maxHeight: '100%', maxWidth: '100%' }}  />
        </div>
    );
};

export default Logo;