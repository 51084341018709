import "./styles.scss";
import Impact from "../../Components/Impact/Impact";
import Slider from "../../Components/Carousel/Slider";

function Partnership() {
  const images = [
    "carousel/carousel-2.png",
    "https://www.youtube.com/embed/rnKsvEXQxTM? =1",
    "carousel/carousel-2.png",
    "https://www.youtube.com/embed/rnKsvEXQxTM? =1",
  ];

  return (
    <>
      <div className="main-vid partners-main-vid">
        <img src="Head2.png" alt="" loading="lazy"/>
        <div className="abs-text">
          <div className="abs-inner">
            <div className="abs-inner-title">setantasports.com</div>
            <div className="abs-inner-under">
              The biggest football community in Georgia
            </div>
          </div>
        </div>
      </div>
      <div className="dreams">
        <div className="dreams-inner dreams-top">
          <div>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. At dolores
            error ipsam labore nam. A aut beatae consequatur dignissimos dolor
            eaque esse et fugiat maxime, nemo pariatur perferendis quo,
            recusandae sunt tenetur veritatis voluptate.
          </div>
          <ul>
            <li>
              <div>some text</div>
              <div className="thicker">Name</div>
            </li>
            <li>
              <div>some text</div>
              <div className="thicker">Name</div>
            </li>
            <li>
              <div>some text</div>
              <div className="thicker">Name</div>
            </li>
          </ul>
        </div>
        <div className="dreams-inner dreams-mid">

          <div className="vid-wrapper">
            <iframe
                className="iframe"
                src="https://www.youtube.com/embed/rnKsvEXQxTM? =1"
                title="YouTube Video"
                allow="accelerometer;  ; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                loading="lazy"
            ></iframe>
          </div>
        </div>
        <div className="dreams-inner dreams-bot">
          <div>
            <div className="thicker">Your Dreams</div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Accusantium, amet blanditiis, consequuntur cumque debitis dolore
              doloribus earum eius facere id inventore ipsum iste necessitatibus
              nihil, quam qui reiciendis sit veritatis! Architecto eius illum
              ratione sequi voluptas? Asperiores cumque dicta dolorem ducimus
              est facilis hic laboriosam, non numquam obcaecati perferendis,
              possimus reiciendis sunt ut vel, voluptatibus?
            </p>
          </div>
          <div>
            <div className="thicker">Your Dreams</div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Accusantium, amet blanditiis, consequuntur cumque debitis dolore
              doloribus earum eius facere id inventore ipsum iste necessitatibus
              nihil, quam qui reiciendis sit veritatis! Architecto eius illum
              ratione sequi voluptas? Asperiores cumque dicta dolorem ducimus
              est facilis hic laboriosam, non numquam obcaecati perferendis,
              possimus reiciendis sunt ut vel, voluptatibus?
            </p>
          </div>
        </div>
      </div>
      <div className="inspire">
        <div className="title-wrapper">
          <div className="title title-black">Inspiring the next generation</div>
          <div className="title title-red">to Only See Great</div>
        </div>
        <div className="vid-wrapper">
          <iframe
            className="iframe"
            src="https://www.youtube.com/embed/rnKsvEXQxTM? =1"
            title="YouTube Video"
            allow="accelerometer;  ; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            loading="lazy"
          ></iframe>
        </div>
      </div>
      <div className="impact partners-impact">
        <div className="partners-impact-head">Results & Conclusion</div>
        <div className="partners-impact-text">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci
          atque cupiditate, dignissimos ducimus hic illum iste, labore maiores
          molestias nihil perspiciatis recusandae saepe sunt, velit veritatis
          vero voluptates voluptatibus? Distinctio, explicabo facere in
          molestias nostrum quis sapiente! Ab asperiores corporis cum fugit
          minima officiis qui?
        </div>
        <Impact />
      </div>
      <div className="partners-slider">
        <Slider items={images} type={"iframe"} />
      </div>
    </>
  );
}

export default Partnership;
